import { logoutUserType } from "../../constants/common_constants"
import { saveLabs, startGettingLabsData } from "../../constants/lab_constants"

const INITIAL_STATE = {
    isLoading:true,
    labs:[],
}

const labsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveLabs:
            return {
                ...state,
                isLoading:false,
                labs:payload
            }
        case startGettingLabsData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          customers: [],
          labs: true
        }
        default:
            return state
    }
}


export default labsReducer