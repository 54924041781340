import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { isJwtExpired } from 'jwt-check-expiration'
import { Dashboard, Login, ErrorPage } from '../container/index'
import { PageLoading } from '../assets/PageLoading'
import { saveTokens, userState } from '../redux/actions/userActions'
import { axiosHandle } from '../api/apiHandler'
import { displayErrorToast } from '../helper/toast_notification_function'

export const RouterApp = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(false)

  const userStates = useSelector(state => state.userStateReducer)

  useEffect(() => {
    setLoading(userStates.isLoading)
    setUser(userStates.user)
  }, [userStates])

  useEffect(async () => {
    let token = localStorage.getItem('accessToken')
    let refreshToken = { refreshToken: localStorage.getItem('refreshToken') }

    if (token && refreshToken.refreshToken) {
      if (isJwtExpired(token) === true) {
        if (isJwtExpired(refreshToken.refreshToken) === false) {
          axiosHandle(token)
            .post('renewAccessToken', refreshToken)
            .then(async res => {
              const newAccessToken = await res.data['NewAccessToken']
              localStorage.setItem('accessToken', newAccessToken)
              let bothtokens = {
                accessToken: newAccessToken,
                refreshToken: localStorage.getItem('refreshToken')
              }
              saveTokens(dispatch, bothtokens)
              userState(dispatch, true)
            })
            .catch(error => {
              displayErrorToast(error)
            })
        } else {
          userState(dispatch, false)
        }
      } else {
        userState(dispatch, true)
        let bothtokens = {
          accessToken: token,
          refreshToken: localStorage.getItem('refreshToken')
        }
        saveTokens(dispatch, bothtokens)
      }
    } else {
      userState(dispatch, false)
    }
  }, [])
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <Router>
          <Routes>
            {!user ? (
              <Route path='/' element={<Login />} />
            ) : (
              <Route path='/*' element={<Dashboard />} />
            )}
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </Router>
      )}
    </>
  )
}