import { apiHandle } from "./apiHandler"


// Get Lab Branch By Lab Id Api 
export const getLabBranchByLabIdApi = async (id,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/getBranchesByLabId?labId=${id}`)
}

// export const getLabBranchByLabIdApi = async (id,token,dispatch,navigate) => {
//     return await apiHandle(token,dispatch,navigate).get(`/getBranchesByLabId`,{labId:id})
// }

