import { getLabsApi } from "../../api/labsApi"
// import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { saveLabs, startGettingLabsData } from "../constants/lab_constants"


 export const  getAllLabs = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingLabsData,        
    })
    getLabsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveLabs,
          payload:e.data
      })
  })
}
