import { combineReducers } from 'redux'
import userStateReducer from './userDataReducers/userState'
import userTokensReducer from './userDataReducers/userTokens'
import ordersReducer from './dataReducers/ordersData'
import customersReducer from './dataReducers/customersData'
import labsReducer from './dataReducers/labsData'
import categoriesReducer from './dataReducers/categoryData'
import individualsReducer from './dataReducers/individualsData'


const reducers = combineReducers({
  userStateReducer,
  userTokensReducer,
  ordersReducer,
  customersReducer,
  labsReducer,
  categoriesReducer,
  individualsReducer
})

export default reducers
