export const formatDate = date => {
  let savedDate = new Date(date)
  return `${savedDate.getDate()}-${savedDate.getMonth() +
    1}-${savedDate.getFullYear()}`
}



export const createOrderExpiry = (date) => {
    var result = new Date(date);
    result.setDate(result.getDate() + 90)
    return formatDate(result)

}





