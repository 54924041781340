import { logoutUserType } from "../../constants/common_constants"
import {saveOrders, startGettingOrdersData} from "../../constants/orders_constants" 

const INITIAL_STATE = {
    isLoading:true,
    orders:[],
}


const ordersReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveOrders:
            return {
                ...state,
                isLoading:false,
                orders:payload
            }
        case startGettingOrdersData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          orders: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default ordersReducer