import { getIndividualsApi } from "../../api/individualsApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { saveIndividuals, startGettingIndividualsData } from "../constants/individual_constants"


 export const  getAllIndividuals = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingIndividualsData,        
    })
    getIndividualsApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveIndividuals,
          payload:e.data
      })
  })
}
