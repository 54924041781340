import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button
} from '@mui/material'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { AddButton } from '../../assets/buttons/AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { allStyles } from '../../allStyles'
import { RiEdit2Line } from 'react-icons/ri'
import { getAllOrders } from '../../redux/actions/ordersActions'
import { formatDate } from '../../helper/date_format_function'
import { getAllCustomers } from '../../redux/actions/customersActions'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'
import { getAllLabs } from '../../redux/actions/labsActions'
import { getAllCategories } from '../../redux/actions/categoriesActions'
 

const columns = [
  { id: 'serialNumber', label: 'S#', minWidth: 20, align: 'center',sortAction:false },
  {
    id: 'Title',
    label: 'Title',
    minWidth: 100,
    align: 'center',
    sortAction:true
  },
  {
    id: 'TopText',
    label: 'Top Text',
    minWidth: 100,
    align: 'center',
    sortAction:true
  },
  // {
  //   id: 'Content',
  //   label: 'Content',
  //   minWidth: 100,
  //   align: 'center',
  //   sortAction:true
  // },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 100,
    align: 'center',
    sortAction:true
  },
  {
    id: 'HeaderImage',
    label: 'Logo Preview',
    minWidth: 100,
    align: 'left',
    sortAction:false
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 100,
    align: 'center',
    sortAction:false
  }
]

export const AllCategories = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [orderData, setOrderData] = useState('ASC')
  const [page, setPage] = React.useState(0)

  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [selectedDataForEdit, setSelectedDataForEdit] = React.useState(null)

  const accessToken = useSelector(state => state.userTokensReducer.tokens.accessToken)
  const categoriesState = useSelector(state => state.categoriesReducer)
  
  const sortingColumns = col => {
      if (orderData === 'ASC') {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('DSC')
      } else if (orderData === 'DSC') {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('ASC')
      } else {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        )
        setRows([...sorted])
        setOrderData('ASC')
      }
  }

  useEffect(() => {
    setLoading(categoriesState.isLoading)
    setRows(categoriesState.categories)
  }, [])

  useEffect(() => {
    setLoading(categoriesState.isLoading)
    setRows(categoriesState.categories)
  }, [categoriesState])



  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Modal open handle through add button
  const addHandle = () => {
    setOpen(true)
  }

  // Modal Close Handle through modal cross button
  const closeClickHandle = () => {
    setOpen(false)

    if (selectedDataForEdit) {
      setSelectedDataForEdit(null)
    }
  }

  const refreshHandle = () => {
    getAllCategories(dispatch, accessToken, navigate)
  }

  const editHandle = selectedData => {
    // setSelectedDataForEdit(selectedData)
    // setOpen(true)
    console.log(selectedData)
  }


  return (
    <Box>
      <Stack
        sx={{ px: 1, mb: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack>
          <Typography variant='h4'>Categories</Typography>
        </Stack>

        <Stack spacing={3} direction='row' alignItems='center'>
          <RefressButton disabled={loading} onClickHandle={refreshHandle} />
          <Stack alignItems={'center'}>
            <AddButton onClickHandle={addHandle} caption='Category' />
          </Stack>
        </Stack>
      </Stack>

      <Stack>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={i}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sortAction ? (
                        <Button sx={allStyles.actionsButtons} onClick={() => sortingColumns(column.id)}>
                          {column.label}
                        </Button>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={true} editAction={true} deleteAction={false} />
              ) : rows.length !==0 ? (
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return (
                        <TableRow hover tabIndex={-1} key={i}>
                          {columns.map((column, ind) => {
                            const value = row[column.id]
                            return column.id === 'serialNumber' ? (
                              <TableCell key={ind} align={column.align}>
                                {page < 1
                                  ? i + 1
                                  : page * rowsPerPage + (i + 1)}
                              </TableCell>
                            ) : column.id === 'HeaderImage' ? (
                              <TableCell key={ind} align={column.align}>
                                {row.HeaderImage ? <Box sx={{ width: '100px' }}>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_PATH_URL}/${value}`}
                                    alt=''
                                    width='100%'
                                  />
                                </Box> : '---'}
                              </TableCell>
                            ) : column.id === 'actions' ? (
                              <TableCell key={ind} align={column.align}>
                                <Stack
                                  direction='row'
                                  spacing={2}
                                  justifyContent='center'
                                >
                                  <IconButton
                                    onClick={() => editHandle(row)}
                                    aria-label='edit'
                                  >
                                    <RiEdit2Line color='#70db70' />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            ) : column.id === 'CreatedDateTime' ? (
                              <TableCell key={ind} align={column.align}>
                                {formatDate(value)}
                              </TableCell>
                            ) : (
                              <TableCell key={ind} align={column.align}>
                                {value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              ) : <NoDataSetMessage lengthOfColumns={columns.length} />}
            </Table>
          </TableContainer>

          {loading ? (
            <PaginationSkeleton />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Stack>
      <ToastContainer />
    </Box>
  )
}


