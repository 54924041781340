import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo/logo_for_login_page.png'

import {
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress
} from '@mui/material'
import { allStyles } from '../allStyles'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { saveTokens, userState } from '../redux/actions/userActions'
import { ToastContainer } from 'react-toastify'
import { displayErrorToast, displaySuccessToast } from '../helper/toast_notification_function'
export const Login = () => {
  const dispatch = useDispatch()
  const [loginData, setLoginData] = useState({})
  const [processLoading, setProcessLoading] = useState(false)

  // const state =  useSelector(state=>state)

  // let onChangeHandle = (e) => {

  //   let id =e.target.id
  //   let value = e.target.value

  //   setLoginData({...loginData,id,value})

  // }


  let login = e => {
    e.preventDefault()
    if (!loginData.email || !loginData.password) {
      alert('Please fill the input fields!')
    } else {
      setProcessLoading(true)
      axios
        .post(
          process.env.REACT_APP_ADMIN_LOGIN_URL,
          loginData
        )

        .then( async res => {
           localStorage.setItem(
            'refreshToken',
            await res.data['tokens']['refreshToken']
          )
          localStorage.setItem('accessToken', await res.data['tokens']['accessToken'])

          let tokens =  {
            accessToken: await res.data['tokens']['accessToken'],
            refreshToken: await res.data['tokens']['refreshToken']
          }

          displaySuccessToast('Logged in Successfully!')
           saveTokens(dispatch, tokens)
           setProcessLoading(false)
          e.target.reset()
           userState(dispatch, true)
        })

        .catch(error => {
          displayErrorToast(error)
          setProcessLoading(false)
        })
    }
  }

  useEffect(() => {
    setLoginData({})
  }, [])

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      direction='column'
      sx={allStyles.loginPage}
    >
      <Paper
        square
        elevation={2}
        children={
          <Stack
            sx={allStyles.loginBox}
            alignItems='center'
            justifyContent='center'
          >
            <Stack
              alignItems='center'
              spacing={3}
              direction='column'
              component='form'
              sx={{
                '& > :not(style)': { width: '35ch' },
                px: 4
              }}
              noValidate
              autoComplete='off'
              onSubmit={login}
            >
              <Stack  alignItems='center' sx={{mb:2}}>
                      <img width='170px' src={logo} />
                    </Stack>
              {/* <Stack>
                <Typography
                  variant='h5'
                  align='center'
                  sx={allStyles.loginText}
                >
                  LOG IN
                </Typography>
              </Stack> */}
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    // id="outlined-basic"
                    type='email'
                    id='email'
                    label='Email'
                    variant='outlined'
                    onChange={e =>
                      setLoginData({ ...loginData, email: e.target.value })
                    }
                  />
                  <TextField
                    // id="outlined-basic"
                    type='password'
                    id='password'
                    label='Password'
                    variant='outlined'
                    onChange={e =>
                      setLoginData({ ...loginData, password: e.target.value })
                    }
                  />
                </Stack>
                {/* <Stack direction='row' justifyContent='center'>
                  <Button
                    sx={allStyles.loginButton}
                    size='large'
                    variant='outlined'
                    // color="#1D3557"
                    type='submit'
                  >
                    Login
                  </Button>
                </Stack> */}

                <Stack
                  direction='row'
                  sx={{ my: 3, position: 'relative' }}
                  justifyContent='center'
                  alignItems='center'
                >
                  <Button
                    type='submit'
                    disabled={processLoading}
                    sx={allStyles.loginButton}
                    variant='outlined'
                    color='primary'
                    size='large'
                  >
                    {processLoading ? '' : 'Log in'}
                  </Button>
                  {processLoading ? (
                    <Stack sx={{ position: 'absolute' }}>
                      {' '}
                      <CircularProgress size={20} disableShrink />
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
      />

      <ToastContainer />
    </Stack>
  )
}
