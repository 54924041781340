import { apiHandle } from "./apiHandler"


// Get All Labs Api 
export const getLabsApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/getLabs')
}


// Get Lab Details By Id Api 
export const getLabDetailsByIdApi = async (id,token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get(`/getLabById?labId=${id}`)
}

