import {getOrdersApi } from "../../api/ordersApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import {saveOrders,startGettingOrdersData} from "../constants/orders_constants"


 export const  getAllOrders = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingOrdersData,        
    })
    getOrdersApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveOrders,
          payload:e.data
      })
  })
}


// export const createTag = (data, token, dispatch ,navigate) => {
//     createTagApi(data, token, dispatch ,navigate)
//       .then((res) => {
//           displaySuccessToast(`Tag Created successfully!`)
//           getAllTags(dispatch,token,navigate)
//         })
//         .catch(error => {
//           displayErrorToast(error)
//         })
//   }
  
  
//   export const updateTag = (data, token, dispatch ,navigate) => {
//     updateTagApi(data, token, dispatch ,navigate)
//       .then((res) => {
//           displaySuccessToast(`Tag Updated successfully!`)
//           getAllTags(dispatch,token,navigate)
//         })
//         .catch(error => {
//           displayErrorToast(error)
//         })
//   }
  


