import { saveCategories, startGettingCategoriesData } from "../../constants/category_constants"
import { logoutUserType } from "../../constants/common_constants"

const INITIAL_STATE = {
    isLoading:true,
    categories:[],
}


const categoriesReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveCategories:
            return {
                ...state,
                isLoading:false,
                categories:payload
            }
        case startGettingCategoriesData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          categories: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default categoriesReducer