import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button
} from '@mui/material'
import { PaginationSkeleton } from '../../assets/PaginationSkeleton'
import { RefressButton } from '../../assets/buttons/RefressButton'
import { AddButton } from '../../assets/buttons/AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { TableBodyDataSkeleton } from '../../assets/TableBodyDataSkeleton'
import { allStyles } from '../../allStyles'
import { IoChevronBackSharp } from 'react-icons/io5'
import { getAllOrders } from '../../redux/actions/ordersActions'
import { formatDate } from '../../helper/date_format_function'
import { NoDataSetMessage } from '../../components/NoDataSetMessage'
import { getLabBranchByLabIdApi } from '../../api/branchAndLabBranchApi'
import { displayErrorToast } from '../../helper/toast_notification_function'

const columns = [
    { id: 'PKLabBranchID', label: 'Lab Branch ID', minWidth: 20, align: 'center',sortAction:false },
    {
      id: 'Title',
      label: 'Title',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    {
      id: 'City',
      label: 'City',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    {
      id: 'State',
      label: 'State',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    {
      id: 'Status',
      label: 'Status',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    {
      id: 'Address',
      label: 'Address',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    {
      id: 'PhoneNumber',
      label: 'Phone Number',
      minWidth: 170,
      align: 'center',
      sortAction:false
    },
    // {
    //   id: 'actions',
    //   label: 'Actions',
    //   minWidth: 170,
    //   align: 'center',
    //   sortAction:false
    // }
  ]
  


const imagePath = process.env.REACT_APP_IMAGE_PATH_URL


export const LabBranchByLabId = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
  
    const [open, setOpen] = useState(false)
  
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [orderData, setOrderData] = useState('ASC')
    const [page, setPage] = React.useState(0)
  
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [selectedDataForEdit, setSelectedDataForEdit] = React.useState(null)

    const perams = useParams()
    const accessToken = useSelector(
      state => state.userTokensReducer.tokens.accessToken
    )



    
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }



    const sortingColumns = col => {
        if (orderData === 'ASC') {
          const sorted = [...rows].sort((a, b) =>
            a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          )
          setRows([...sorted])
          setOrderData('DSC')
        } else if (orderData === 'DSC') {
          const sorted = [...rows].sort((a, b) =>
            a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          )
          setRows([...sorted])
          setOrderData('ASC')
        } else {
          const sorted = [...rows].sort((a, b) =>
            a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          )
          setRows([...sorted])
          setOrderData('ASC')
        }
    }
  
    useEffect(() => {
        getLabBranchByLabIdApi(perams.id, accessToken, dispatch, navigate)
          .then(e => {
            if (e.data) {
              setRows(e.data)
              setLoading(false)
            }
          })
          .catch(error => {
            setRows(null)
            setLoading(false)
            displayErrorToast(error)
          })
      }, [])


      const goBackToLabHandle =() =>{
        // navigate(`/labDetails/${perams.id}`)
        window.history.back()
      }






      return (
        <Box>
        <Stack
          sx={{ px: 1, mb: 2 }}
        //   direction='row'
        //   alignItems='center'
        //   justifyContent='space-between'
        spacing={2}
        >

            <Stack direction='row'>
              <Button
              onClick={goBackToLabHandle}
                sx={allStyles.actionsButtons}
                variant='outlined'
                color='info'
                startIcon={<IoChevronBackSharp />}
              >
                Go Back to Lab
              </Button>
            </Stack>
        
          <Stack>
            <Typography variant='h4'>Lab Branches</Typography>
          </Stack>
  
          {/* <Stack spacing={3} direction='row' alignItems='center'>
            <RefressButton disabled={loading} onClickHandle={refreshHandle} />
            <Stack alignItems={'center'}>
              <AddButton onClickHandle={addHandle} caption='Tag' />
            </Stack>
          </Stack> */}
        </Stack>
  
        <Stack>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sortAction ? (
                          <Button sx={allStyles.actionsButtons} onClick={() => sortingColumns(column.id)}>
                            {column.label}
                          </Button>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
  
                {loading ? (
                  <TableBodyDataSkeleton numberOfColumns={columns.length} imagePreview={false} editAction={true} deleteAction={false} />
                ) : rows && rows.length !== 0 ? (
                  <TableBody>
                    {rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, i) => {
                        return (
                          <TableRow hover tabIndex={-1} key={i}>
                            {columns.map((column, ind) => {
                              const value = row[column.id]
                              return column.id === 'actions' ? (
                                <TableCell key={ind} align={column.align}>
                                  <Stack
                                    direction='row'
                                    spacing={2}
                                    justifyContent='center'
                                  >
                                  
                                    {/* <Button sx={allStyles.actionsButtons} onClick={()=>generateReportHandle(row.PKOrderID)} >Generate Report</Button> */}
                                  </Stack>
                                </TableCell>
                              ) : column.id === 'CreatedDateTime' ? (
                                <TableCell key={ind} align={column.align}>
                                  {formatDate(value)}
                                </TableCell>
                              ) : column.id === 'Notes' ? (
                                <TableCell key={ind} align={column.align}>
                                  {value ? value : '---'}
                                </TableCell>
                              ) : column.id === 'FullName' ? (
                                <TableCell key={ind} align={column.align}>
                                  {row.Customer.FirstName} {row.Customer.LastName}
                                </TableCell>
                              ) : column.id === 'EmailAddress' ? (
                                <TableCell key={ind} align={column.align}>
                                  {row.Customer.EmailAddress}
                                </TableCell>
                              ) : (
                                <TableCell key={ind} align={column.align}>
                                  {value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                ) : !rows ? <NoDataSetMessage lengthOfColumns={columns.length} message='No Data Found!' /> : rows  && rows.length === 0 ? <NoDataSetMessage lengthOfColumns={columns.length} /> : null}
              </Table>
            </TableContainer>
  
            {loading ? (
              <PaginationSkeleton />
            ) : rows && rows.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </Paper>
        </Stack>
        <ToastContainer />
      </Box>
      )
}
