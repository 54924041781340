import { logoutUserType } from "../../constants/common_constants"
import { saveCustomers, startGettingCustomersData } from "../../constants/customer_constants"

const INITIAL_STATE = {
    isLoading:true,
    customers:[],
}


const customersReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveCustomers:
            return {
                ...state,
                isLoading:false,
                customers:payload
            }
        case startGettingCustomersData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          customers: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default customersReducer