import { CircularProgress, Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { displayErrorToast } from '../../helper/toast_notification_function'
import { getLabDetailsByIdApi } from '../../api/labsApi'
import { allStyles } from '../../allStyles'

const tableListItems = [
  {
    title1: 'Lab ID',
    value1: 'PKLabID',

    title2: 'Title',
    value2: 'Title'
  },

  { title1: 'Address', value1: 'Address', colSpan: 3 },
  {
    title1: 'Access URL',
    value1: 'AccessURL',

    title2: 'Status',
    value2: 'Status'
  },

  {
    title1: 'Phone Number',
    value1: 'PhoneNumber',

    title2: 'FaxNumber',
    value2: 'Fax Number'
  },
  {
    title1: 'Meta Title',
    value1: 'MetaTitle',

    title2: 'Meta Keyword',
    value2: 'MetaKeyword'
  },
  { title1: 'Meta Description', value1: 'MetaDescription', colSpan: 3 },

  { title1: 'Content', value1: 'Content', colSpan: 3 },
  {
    title1: 'Created Date',
    value1: 'CreatedDateTime',

    title2: 'No Index Follow Tag',
    value2: 'NoIndexFollowTag'
  }
]

const imagePath = process.env.REACT_APP_IMAGE_PATH_URL

export const LabDetailsById = () => {
  const perams = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [getByIdData, setGetByIdData] = useState(null)

  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )

  useEffect(() => {
    getLabDetailsByIdApi(perams.id, accessToken, dispatch, navigate)
      .then(e => {
        if (e.data) {
          setGetByIdData(e.data)
          setLoading(false)
        }
      })
      .catch(error => {
        setGetByIdData(null)
        setLoading(false)
        displayErrorToast(error)
      })
  }, [])

  const viewLabBranchDetailsHandle = () =>{
    navigate(`/labBranchDetails/${getByIdData.PKLabID}`)
  }

  return (
    <Stack>
      {loading ? (
        <Stack sx={{ py: 4 }} alignItems='center'>
          {' '}
          <CircularProgress />
        </Stack>
      ) : getByIdData ? (
        <Stack sx={{ px: 3 }}>
          <Stack>
            {' '}
            <Typography variant='h4' align='center'>
              LAB DETAILS
            </Typography>
          </Stack>

          <Stack spacing={2}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ py: '20px', px: 1 }}
            >
              {getByIdData.LogoImage ? (
                <Stack spacing={1}>
                  <Typography sx={allStyles.labDetailsHeadings}>
                    Logo Preview
                  </Typography>

                  <img
                    width='300px'
                    src={`${imagePath}/${getByIdData.LogoImage}`}
                    alt=''
                  />
                </Stack>
              ) : (
                <Typography>---</Typography>
              )}

<Stack direction='row' sx={{ my: 3 }} spacing={3}>
              <Stack>
                <Button
                  sx={allStyles.actionsButtons}
                  variant='outlined'
                  color='success'
                >
                  Update Lab
                </Button>
              </Stack>

              <Stack>
                <Button
                  sx={allStyles.actionsButtons}
                  variant='outlined'
                  color='warning'
                >
                  Add Branch
                </Button>
              </Stack>
            </Stack>
            </Stack>
            <Stack spacing={3}>
              <table className='labDetailsByIdTable'>
                <tbody>
                  {tableListItems.map((e, i) =>
                    e.colSpan ? (
                      <tr key={i}>
                        <th>{e.title1}</th>
                        <td colSpan={e.colSpan}>
                          {getByIdData[e.value1]
                            ? getByIdData[e.value1]
                            : '---'}
                        </td>
                      </tr>
                    ) : (
                      <tr key={i}>
                        <th>{e.title1}</th>
                        <td>
                          {getByIdData[e.value1]
                            ? getByIdData[e.value1]
                            : '---'}
                        </td>
                        <th>{e.title2}</th>
                        <td>
                          {getByIdData[e.value2]
                            ? getByIdData[e.value2]
                            : '---'}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Stack>
          </Stack>

          <Stack direction='row' sx={{ my: 3 }} spacing={3}>
            <Stack>
              <Button
              onClick={viewLabBranchDetailsHandle}
                sx={allStyles.actionsButtons}
                variant='outlined'
                color='info'
              >
                View Lab Branch
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Typography sx={{ my: 3 }}>No Data Found</Typography>
        </Stack>
      )}
    </Stack>
  )
}
