import { logoutUserType } from "../constants/common_constants"
import { userAciveState, activeUserTokens,userTokensExpired  } from "../constants/user_state_constants"


export const userState = (dispatch, user) => {
  return dispatch({
    type: userAciveState,
    user: user
  })
}

export const saveTokens = (dispatch, payload) => {
  return dispatch({
    type: activeUserTokens,
    payload: payload
  })
}

export const RemoveTokens = dispatch => {
  return dispatch({
    type: userTokensExpired
  })
}

export const logoutUser = (dispatch) => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  return dispatch({
    type:logoutUserType
  })
}


