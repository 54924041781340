import { CircularProgress, Stack, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import currentLogo from '../../assets/images/logo/logo_for_login_page.png'
import { Requisition_PDF_Generator } from '../../components/Requisition_PDF_Generator'
import { displayErrorToast } from '../../helper/toast_notification_function'
import { getOrderRequisitionApi } from '../../api/orderRequisitionApi'
import { createOrderExpiry } from '../../helper/date_format_function'

const imagePath = process.env.REACT_APP_IMAGE_PATH_URL

export const OrderRequisition = () => {
  const perams = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [requisitionData, setRequisitionData] = useState(null)

  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )


  useEffect(() => {
    getOrderRequisitionApi(perams.id, accessToken, dispatch, navigate)
      .then(e => {
        if (
          e.data
        ) {
          setRequisitionData(e.data)
          setLoading(false)
        }
      })
      .catch(error => {
        setRequisitionData(null)
        setLoading(false)
        displayErrorToast(error)
      })
  }, [])

  return (
    <Stack alignItems='center'>
      {!loading && (
        <Requisition_PDF_Generator requisitionData={requisitionData} />
      )}
      {loading ? (
        <Stack sx={{ py: 4 }} alignItems='center'>
          {' '}
          <CircularProgress />
        </Stack>
      ) : requisitionData ? (
        <Stack alignItems='center'>
          <Stack sx={{ px: 3, width: 800 }} spacing={2} id='my-table'>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ py: '20px', px: 1 }}
            >
              {requisitionData.orderRequisition[0].Branch.Lab.LogoImage ? (
                <Stack>
                  <img
                    width='150px'
                    src={`${imagePath}/${requisitionData.orderRequisition[0].Branch.Lab.LogoImage}`}
                    alt=''
                  />
                </Stack>
              ) : (
                <Typography>Logo not found</Typography>
              )}
              <Stack>
                <img width='150px' src={currentLogo} alt='' />
              </Stack>
            </Stack>

            {/* Table 01 Client Information */}

            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td colSpan={4}>Client Information</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='cellTitle'>Account #</td>
                    <td>{requisitionData.clientInformation.account}</td>
                    <td className='cellTitle'>Account Name</td>
                    <td>{requisitionData.clientInformation.accountName}</td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>Bill Type </td>
                    <td>{requisitionData.clientInformation.billType}</td>
                    <td className='cellTitle'>Phone #</td>
                    <td>{requisitionData.clientInformation.phone}</td>
                  </tr>
                </tbody>
              </table>
            </Stack>

            {/* Table 02 Provider Information */}
            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td colSpan={4}>Provider Information</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='cellTitle'>Physician Name</td>
                    <td>{requisitionData.physicianIdentity.physicianName}</td>
                    <td className='cellTitle'>NPI</td>
                    <td>{requisitionData.physicianIdentity.NPI}</td>
                  </tr>
                </tbody>
              </table>
            </Stack>

            {/* Table 03 Patient Information */}
            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td colSpan={4}>Patient Information</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='cellTitle'>ID</td>
                    <td>
                      {
                        requisitionData.orderRequisition[0].Customer
                          .PKCustomerID
                      }
                    </td>
                    <td className='cellTitle'>Order Expiry</td>
                    <td>
                      {createOrderExpiry(requisitionData.orderRequisition[0].Customer
                          .CreatedDateTime)
                        
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>Name</td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.FirstName}{' '}
                      {requisitionData.orderRequisition[0].Customer.LastName}
                    </td>
                    <td className='cellTitle'>Sex</td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.Gender}
                    </td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>Date of Birth </td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.DateOfBirth}{' '}
                    </td>
                    <td className='cellTitle'>Phone #</td>
                    <td>
                      {
                        requisitionData.orderRequisition[0].Customer
                          .MobileNumber
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>City </td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.City}{' '}
                    </td>
                    <td className='cellTitle'>State</td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.State}
                    </td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>Address</td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.Address}
                    </td>
                    <td className='cellTitle'>ZIP</td>
                    <td>
                      {requisitionData.orderRequisition[0].Customer.ZIP}
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </Stack>

            {/* Table 03 Lab Branch Information */}
            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td colSpan={4}>Lab Branch Information</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='cellTitle'>Lab Name</td>
                    <td>
                      {requisitionData.orderRequisition[0].Branch.Lab.Title}
                    </td>
                    <td className='cellTitle'>Branch Name</td>
                    <td>{requisitionData.orderRequisition[0].Branch.Title}</td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>City</td>
                    <td>
                      {requisitionData.orderRequisition[0].Branch.City},
                      {requisitionData.orderRequisition[0].Branch.State}
                    </td>
                    <td className='cellTitle'>Phone #</td>
                    <td>
                      {requisitionData.orderRequisition[0].Branch.PhoneNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className='cellTitle'>Address</td>
                    <td colSpan={3}>
                      {requisitionData.orderRequisition[0].Branch.Address}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Stack>

            {/* Table 03 Tests and Tests Code */}
            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td width='70%'>Test</td>
                    <td>Test Code</td>
                  </tr>
                </thead>
                <tbody>
                  {requisitionData.filteredOrderItems.map((e, i) => (
                    <tr key={i}>
                      <td>{e.Test_Name}</td>
                      <td>{e.Test_Code}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Stack>

            {/* Table 04 Disclaimer*/}
            <Stack>
              <table className='requisitionTable'>
                <thead>
                  <tr>
                    <td>Disclaimer</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='disclaimerSection'>
                      These documents may contain confidential information and
                      is intended only for the individual named or purchased
                      these services. It may also be privileged or otherwise
                      protected by work product immunity or other legal rules.
                      If you are not the intended recipient you are notified
                      that disclosing, copying, forwarding or otherwise
                      distributing or taking any action in reliance on the
                      contents of this email and any attachments is strictly
                      prohibited.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Typography sx={{ my: 3 }}>No Data Found</Typography>
        </Stack>
      )}
    </Stack>
  )
}
