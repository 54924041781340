import React, { useState } from 'react'
import {Stack, Button } from '@mui/material'
import { allStyles } from '../allStyles'
import { BsCloudDownload } from 'react-icons/bs'
// import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {
  idirectLogoBase64,
} from '../helper/imageBase64s'
import { createOrderExpiry } from '../helper/date_format_function'

const imagePath = process.env.REACT_APP_IMAGE_PATH_URL

export const Requisition_PDF_Generator = ({ requisitionData, imageBase64 }) => {
  const headerFillColor = '#D3D3D3'
  const tableTheme = 'grid'

  const [base64Image, setBase64Image] = useState('')

 

  //  const params = useParams()
  // //  console.log(params['*'].split('/'))

  //   useEffect(() => {
  //     if(requisitionData){
  //       setloading(false)
  //     }
  //     else{
  //       setloading(true)
  //     }
  //   }, [requisitionData])
  
  const generatePdfHandle = async () => {
    const doc = new jsPDF()
    
    const imageUrl =
      imagePath + '/' + requisitionData.orderRequisition[0].Branch.Lab.LogoImage

    if (requisitionData) {
      let testBodyArray = []
      let singleElement

      requisitionData.filteredOrderItems.forEach(element => {
        singleElement = [
          {
            content: element.Test_Name,
            minCellWidth: 10,
            styles: { fontStyle: 'bold', halign: 'left' }
          },
          {
            content: element.Test_Code,
            styles: { halign: 'left' }
          }
        ]

        testBodyArray.push(singleElement)
      })


      fetch(imageUrl).then(async response => {
        const imageBlob = await response.blob()
        const reader = new FileReader()
        reader.readAsDataURL(imageBlob)
        reader.onloadend = () => {
          const base64data = reader.result

        // Logo Images Section
          doc.addImage(base64data,'PNG', 17, 28,60,0)
          doc.addImage(idirectLogoBase64,'PNG', 162, 22)

          // Auto-Table Section Start

          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              valign: 'center',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0,
              minCellHeight: 30
            },

            body: [
              [
                {
                  content: '',
                  colSpan: 4,
                  styles: { halign: 'left' }
                }
              ]
            ]
          })

          // ---- CLIENT INFORMATION ----
          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'CLIENT INFORMATION',
                  colSpan: 4,
                  styles: { fillColor: headerFillColor }
                }
              ]
            ],

            body: [
              [
                {
                  content: 'Account #',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.clientInformation.account,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Account Name',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.clientInformation.accountName,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'Bill Type',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.clientInformation.billType,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Phone #',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.clientInformation.phone,
                  styles: { halign: 'left' }
                }
              ]
            ]
          })

          // ---- PROVIDER INFORMATION ----
          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'PROVIDER INFORMATION',
                  colSpan: 4,
                  styles: { fillColor: headerFillColor }
                }
              ]
            ],

            body: [
              [
                {
                  content: 'Physician Name',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.physicianIdentity.physicianName,
                  styles: { halign: 'left' }
                },
                {
                  content: 'NPI',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.physicianIdentity.NPI,
                  styles: { halign: 'left' }
                }
              ]
            ]
          })

          // ---- PATIENT INFORMATION ----
          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'PATIENT INFORMATION',
                  colSpan: 4,
                  styles: { fillColor: headerFillColor }
                }
              ]
            ],

            body: [
              [
                {
                  content: 'ID',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:
                    requisitionData.orderRequisition[0].Customer.PKCustomerID,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Order Expiry',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:createOrderExpiry(requisitionData.orderRequisition[0].Customer
                    .CreatedDateTime)
                    ,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'Name',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:
                    requisitionData.orderRequisition[0].Customer.FirstName +
                    ' ' +
                    requisitionData.orderRequisition[0].Customer.LastName,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Sex',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Customer.Gender,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'Date of Birth',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:
                    requisitionData.orderRequisition[0].Customer.DateOfBirth,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Phone #',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:
                    requisitionData.orderRequisition[0].Customer.MobileNumber,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'City',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Customer.City,
                  styles: { halign: 'left' }
                },
                {
                  content: 'State',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Customer.State,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'Address',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Customer.Address,
                  styles: { halign: 'left' }
                },
                {
                  content: 'ZIP',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Customer.ZIP,
                  styles: { halign: 'left' }
                }
              ]
            ]
          })

          // ---- LAB BRANCH INFORMATION ----
          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'LAB BRANCH INFORMATION',
                  colSpan: 4,
                  styles: { fillColor: headerFillColor }
                }
              ]
            ],

            body: [
              [
                {
                  content: 'Lab Name',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Branch.Lab.Title,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Branch Name',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Branch.Title,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'City/State',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: `${requisitionData.orderRequisition[0].Branch.City}/${requisitionData.orderRequisition[0].Branch.State}`,
                  styles: { halign: 'left' }
                },
                {
                  content: 'Phone #',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content:
                    requisitionData.orderRequisition[0].Branch.PhoneNumber,
                  styles: { halign: 'left' }
                }
              ],
              [
                {
                  content: 'Address',
                  styles: { fontStyle: 'bold', halign: 'left' }
                },
                {
                  content: requisitionData.orderRequisition[0].Branch.Address,
                  colSpan: 3,
                  styles: { halign: 'left' }
                }
              ]
            ]
          })

          // ---- TEST AND TEST CODE ----
          doc.autoTable({
            theme: tableTheme,
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'TEST',
                  // colSpan: 3,
                  minCellWidth: 10,
                  styles: { fillColor: headerFillColor }
                },
                {
                  content: 'TEST CODE',
                  // colSpan: 1,
                  styles: { fillColor: headerFillColor }
                }
              ]
            ],

            body: testBodyArray
          })

          // ---- DISCLAIMER ----
          doc.autoTable({
            theme: tableTheme,
            showHead: 'firstPage',
            styles: {
              halign: 'left',
              fillColor: null,
              textColor: 'black',
              lineWidth: 0.25,
              lineColor: 'black'
            },
            head: [
              [
                {
                  content: 'DISCLAIMER',
                  styles: { fillColor: '#D3D3D3' }
                }
              ]
            ],

            body: [
              [
                {
                  content:
                    'These documents may contain confidential information and is intended only for the individual named or purchased these services. It may also be privileged or otherwise protected by work product immunity or other legal rules. If you are not the intended recipient you are notified that disclosing, copying, forwarding or otherwise distributing or taking any action in reliance on the contents of this email and any attachments is strictly prohibited.',
                  styles: { fontSize: 8 }
                }
              ]
            ]
          })
          doc.save('order-requisition.pdf')
        }
      })
    }
  }

  return (
    <Stack>
      <Button
        sx={allStyles.actionsButtons}
        onClick={generatePdfHandle}
        variant='outlined'
        startIcon={<BsCloudDownload size={20} />}
      >
        Download PDF
      </Button>
    </Stack>
  )
}
