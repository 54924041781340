import { Button } from '@mui/material';
import React from 'react';
import { GrRotateRight } from 'react-icons/gr';
import { allStyles } from '../../allStyles';

export const RefressButton = (props) => {
    const {onClickHandle,disabled} = props
  return <Button disabled={disabled} onClick={onClickHandle} sx={allStyles.refressButton} endIcon={<GrRotateRight size={20} /> }>
  Refresh
</Button>
};
