import { apiHandle } from "./apiHandler"


// Get All Orders Api 
export const getOrdersApi = async (token,dispatch,navigate) => {
    return await apiHandle(token,dispatch,navigate).get('/orders')
}


// Create New Tag Api
// export const createTagApi = async (data, token, dispatch, navigate) => {
//     return await apiHandle(token, dispatch, navigate).post('/createTag',data)
//   }
  
//   // Update Tag Api
//   export const updateTagApi = async (data, token, dispatch, navigate) => {
//     return await apiHandle(token, dispatch, navigate).put(`/updateTag?id=${data.PKTagId}`,{NameOfTag:data.NameOfTag})
//   }
  