import React, { useEffect, useState } from 'react'
import { Routes, Route, Link, useNavigate } from 'react-router-dom'
import { AllCustomers, AllOrders, OrderRequisition } from './index'
import {
  Stack,
  Typography,
  SvgIcon,
  MenuItem,
  Divider,
  Paper,
  IconButton
} from '@mui/material'
import { FiMenu } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { AiOutlineDashboard } from 'react-icons/ai'
import { BsPersonCheck } from 'react-icons/bs'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { allStyles } from '../allStyles'
import { logoutUser, RemoveTokens } from '../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutButton } from '../assets/buttons/LogoutButton'
import { getAllOrders } from '../redux/actions/ordersActions'
import { getAllCustomers } from '../redux/actions/customersActions'
import { Labs } from './labs/Labs'
import { BiCategory, BiTestTube } from 'react-icons/bi'
import { getAllLabs } from '../redux/actions/labsActions'
import { AllCategories } from './categories/AllCategories'
import { getAllCategories } from '../redux/actions/categoriesActions'
import { RiTestTubeFill } from 'react-icons/ri'
import { AllIndividuals } from './individuals/Individuals'
import { getAllIndividuals } from '../redux/actions/individualsActions'
import { LabDetailsById } from './labs/LabDetailsById'
import { LabBranchByLabId } from './branchAndLabBranch/LabBranchByLabId'

let textColor = '#1D3557'

export const Dashboard = () => {
  const navigate = useNavigate()

  const [sideBarActive, setSideBarActive] = useState(true)

  const userStatus = useSelector(state => state.userStateReducer.user)
  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )
  const state = useSelector(state => state)

 
  const dispatch = useDispatch()

  let logoutHandle = () => {
    logoutUser(dispatch)
    navigate('/')
  }
  useEffect(() => {
    getAllOrders(dispatch, accessToken, navigate)
    getAllCustomers(dispatch, accessToken, navigate)
    getAllLabs(dispatch, accessToken, navigate)
    getAllCategories(dispatch, accessToken, navigate)
    getAllIndividuals(dispatch, accessToken, navigate)
  }, [])

  useEffect(() => {
    if (!userStatus) {
      RemoveTokens(dispatch)
    }
  }, [userStatus])

  let listOfMenuItems = [
    {
      caption: 'Orders',
      linkTo: '',
      icon: <AiOutlineUnorderedList />,
      element: <AllOrders />,
      menuListItem:true
    },
    {
      caption: 'Customers',
      linkTo: 'customers',
      icon: <BsPersonCheck />,
      element: <AllCustomers />,
      menuListItem:true
    },
    {
      caption: 'Labs',
      linkTo: 'labs',
      icon: <BiTestTube />,
      element: <Labs />,
      menuListItem:true
    },
    {
      caption: 'Categories',
      linkTo: 'categories',
      icon: <BiCategory />,
      element: <AllCategories />,
      menuListItem:true
    },
    {
      caption: 'Individual Tests',
      linkTo: 'individuals',
      icon: <RiTestTubeFill />,
      element: <AllIndividuals />,
      menuListItem:true
    },
    {
      caption: 'Order Requisition',
      linkTo: 'OrderRequisition/:id',
      element: <OrderRequisition />,
      menuListItem:false
    },
    {
      caption: 'lab Details Page',
      linkTo: 'labDetails/:id',
      element: <LabDetailsById />,
      menuListItem:false
    },
    {
      caption: 'lab Branch Details Page',
      linkTo: 'labBranchDetails/:id',
      element: <LabBranchByLabId />,
      menuListItem:false
    }
  ]

  return (
    <>
      <Stack>
        <Stack className={`sideBar ${sideBarActive ? 'active' : ''}`}>
          <Stack sx={{ px: 3, py: 2 }}>

            {/* <Stack direction='row' alignItems='start'>
              <SvgIcon sx={{ overflow: 'initial', marginRight: '25px' }}>
                <AiOutlineDashboard color='white' fontSize={23} />
              </SvgIcon>
              <Typography
                align='right'
                sx={allStyles.dashboardSideBarHeading}
                noWrap
              >
                Dashboard
              </Typography>
            </Stack> */}

            <Divider
              sx={{ backgroundColor: 'gray',mt:5}}
              orientation='horizontal'
              variant='fullWidth'
              light
            />

            <Stack sx={{ py: 3, mt: 2}} direction='column' spacing={4}>
              {listOfMenuItems.map(
                (e, i) =>
                  e.menuListItem === true && (
                    <Link key={i} color='white' to={e.linkTo}>
                      <MenuItem
                        disableGutters
                        dense
                        key={i}
                        autoFocus={false}
                        style={{
                          color: 'white',
                          textDecoration: 'none'
                        }}
                      >
                        <Stack direction='row' alignItems='center'>
                          <SvgIcon
                            sx={{
                              overflow: 'initial',
                              color: 'white',
                              fontSize: 23,
                              marginRight: '25px'
                            }}
                          >
                            {e.icon}
                          </SvgIcon>

                          <Stack>
                            <Typography
                              sx={{ fontSize: 18, fontWeight: 'light' }}
                            >
                              {e.caption}
                            </Typography>
                          </Stack>
                        </Stack>
                      </MenuItem>
                    </Link>
                  )
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack className='pages'>
          <Paper
            elevation={2}
            sx={{ px: 2, py: 1, mb: 4, borderRadius: 0 }}
            children={
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                {sideBarActive ? (
                  <Stack direction='row' alignItems='center' sx={{transition: 'all 0.25s ease-in-out'}}>
                    <IconButton
                      color='primary'
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      {' '}
                      <IoIosArrowBack color={textColor} size={25} />
                    </IconButton>

                    {/* <Stack>
                      <img width='100px' src={logo} />
                    </Stack> */}
                  </Stack>
                ) : (
                  <Stack direction='row' sx={{marginLeft:'-70px',transition: 'all 0.25s ease-in-out'}}>
                    <IconButton
                      color='primary'
                      onClick={() => setSideBarActive(!sideBarActive)}
                    >
                      {' '}
                      <FiMenu color='white' size={23} />
                    </IconButton>

                    {/* <Stack>
                      <img width='100px' src={logo} />
                    </Stack> */}
                  </Stack>
                )}

                <Stack>
                  <LogoutButton onClickHandle={logoutHandle} />
                </Stack>
              </Stack>
            }
          />
          <Stack sx={{ px: 3 }}>
            <Routes>
              {listOfMenuItems.map((e, i) => (
                <Route key={i} path={e.linkTo} element={e.element} />
              ))}
            </Routes>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
