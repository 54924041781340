import { logoutUserType } from "../../constants/common_constants"
import { saveCustomers, startGettingCustomersData } from "../../constants/customer_constants"
import { saveIndividuals, startGettingIndividualsData } from "../../constants/individual_constants"

const INITIAL_STATE = {
    isLoading:true,
    individuals:[],
}


const individualsReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case saveIndividuals:
            return {
                ...state,
                isLoading:false,
                individuals:payload
            }
        case startGettingIndividualsData:
            return {
                ...state,
                isLoading:true,
            }
            
    case logoutUserType:
        return {
          ...state,
          individuals: [],
          isLoading: true
        }
        default:
            return state
    }
}


export default individualsReducer