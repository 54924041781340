import React from 'react';
import { CircularProgress, Stack } from "@mui/material"
import { allStyles } from '../allStyles';


export const PageLoading = () => {
  return(
    <Stack sx={allStyles.pageLoading} justifyContent='center' alignItems='center'><CircularProgress /></Stack>
  )
};
