import { getCustomersApi } from "../../api/customersApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { saveCustomers, startGettingCustomersData } from "../constants/customer_constants"


 export const  getAllCustomers = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingCustomersData,        
    })
    getCustomersApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveCustomers,
          payload:e.data
      })
  })
}
