import { getCategoriesApi } from "../../api/categoriesApi"
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import { saveCategories, startGettingCategoriesData } from "../constants/category_constants"


 export const  getAllCategories = (dispatch,token,navigate) =>{
     dispatch({
        type:startGettingCategoriesData,        
    })
    getCategoriesApi(token,dispatch,navigate).then((e)=>{
      return dispatch({
          type:saveCategories,
          payload:e.data
      })
  })
}
